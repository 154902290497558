<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <v-row>
            <v-col>
                <v-container class="my-5">
                    <v-row>
                        <v-col cols="12" md="12">
                            <v-row>
                                <v-col cols="12" class="sub-title-3 mt-2">
                                    {{resvDate}} 예약목록
                                </v-col>
                                <v-col>
                                    <div class="overflow-x-auto">
                                        <table class="adminListTable table-top-s table-top-s-b ">
                                            <colgroup>
                                                <col style="width:5%"/>
                                                <col style="width:10%"/>
                                                <col style="width:10%"/>
                                                <col style="width:5%"/>
                                                <col style="width:10%"/>
                                                <col style="width:10%"/>
                                                <col style="width:10%"/>
                                                <col style="width:14%"/>
                                                <col style="width:10%"/>
                                                <col style="width:15%"/>
                                            </colgroup>
                                            <tr>
                                                <th>객실명</th>
                                                <th>입실일</th>
                                                <th>숙박일</th>
                                                <th>인원</th>
                                                <th>예약자</th>
                                                <th>입금자</th>
                                                <th>결제금액</th>
                                                <th>전화번호</th>
                                                <th>예약상태</th>
                                                <th>관리자메모</th>
                                            </tr>
                                            <tr
                                                    v-for="(item,i) in resvList"
                                                    :key="i">
                                                <td>{{item.roomNm}}</td>
                                                <td>{{item.resvDate}}</td>
                                                <td>{{item.resvDateRange}}</td>
                                                <td>{{item.resvNop}}명</td>
                                                <td>{{item.resvName}}</td>
                                                <td>{{item.payName}}</td>
                                                <td>{{item.resvPrice | comma}}</td>
                                                <td>{{item.resvPhone}}</td>
                                                <td>
                                                    <template v-if="item.resvGubun === '1'">
                                                        <span style="color:blue;">{{item.resvGubunDesc}}</span>
                                                    </template>
                                                    <template v-else-if="item.resvGubun === '2'">
                                                        <span style="color:black;">{{item.resvGubunDesc}}</span>
                                                    </template>
                                                    <template v-else>
                                                        <span style="color:red;">{{item.resvGubunDesc}}</span>
                                                    </template>
                                                </td>
                                                <td>
                                                    {{item.adminMemo}}
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </v-col>

                                <v-col cols="12" class="my-1" md="12">
                                    <v-col cols="12" style="text-align: center">
                                        <v-btn
                                                class="mx-4 center-block"
                                                @click="btnClick(true)"
                                        >
                                            프린트
                                        </v-btn>
                                        <v-btn
                                                class="mx-4 center-block"
                                                @click="btnClick(false)"
                                        >
                                            엑셀저장
                                        </v-btn>
                                    </v-col>

                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                </v-container>
            </v-col>
        </v-row>

    </div>
</template>

<script>
    export default {
        name: 'AdminResvCal',
        components: {},
        data: () => ({

            resvDate: "",
            resvList: [],

        }),
        created: function () {
            // 맨위로
            window.scrollTo(0, 0);
            //this.$isAdminCheck(this.$store.state.admin.isLogin);
            //this.$isAuthCheck(this.$getCookie("AUTH"));
            this.resvDate = this.$getParameterByName('resvDate');

            if (this.resvDate === "") {
                alert("날짜 정보가 없습니다. 다시 시도해주세요.");
                //window.history.back();
                window.close();
            } else {
                this.getInfo();
            }
        },
        methods: {
            btnClick(val) {

                if (val) {
                    window.print();
                } else {

                    let BASE_URL = void 0;

                    let host = void 0;
                    let port = void 0;

                    switch (document.location.hostname) {
                        case "localhost":
                        case "127.0.0.1": {
                            host = "192.168.0.100";
                            port = "8082";
                            break;
                        }
                        case "211.238.111.60": {
                            host = "211.238.111.60";
                            port = "58082";
                            break;
                        }
                        case "192.168.0.100":{
                            host = "192.168.0.100";
                            port = "8082";
                            break;
                        }
                        case "valley.anystar4u.com": {
                            host = "valley.anystar4u.com";
                            port = null;
                            break;
                        }
                        default: {
                            host = document.location.hostname;
                            port = null;
                            break;
                        }
                    }

                    let protocol = document.location.protocol;

                    BASE_URL = protocol+"//" + host + (port == null? "" : (":" + port));

                    location.href = BASE_URL + "/api/admin1/excel/resv/getListToDate.do?resvDate=" + this.resvDate;

                }

            },

            getInfo() {

                let fdata = {
                    resvDate: this.resvDate
                };

                return this.$store.dispatch("admin/getResvListToDate", fdata)
                    .then((resp) => {
                        setTimeout(() => {

                            this.resvList = resp.message;

                            // 핸드폰 번호 변경
                            let _this = this;
                            this.resvList.forEach(function (e) {
                                e.resvPhone = _this.$setPhoneNum(e.resvPhone);
                            })


                        }, 300)
                    })
                    .catch((err) => {
                    })
            },

        },

    }
</script>

<style>

</style>

